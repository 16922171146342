import { render, staticRenderFns } from "./SalesDetail.vue?vue&type=template&id=49ffdbba&scoped=true"
import script from "./SalesDetail.vue?vue&type=script&lang=js"
export * from "./SalesDetail.vue?vue&type=script&lang=js"
import style0 from "./SalesDetail.vue?vue&type=style&index=0&id=49ffdbba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49ffdbba",
  null
  
)

export default component.exports